<template>
	<WwModal
		:modal-id="MENU_FILTER_MODAL"
		modal-border-radius="rounded-none"
		modal-position="items-start"
		modal-margin="m-0"
		modal-padding="p-2"
		modal-height="h-screen"
		max-width="w-full"
		:header-offset="180"
		transition-direction="right"
	>
		<template #close="{ handleHideButtonClick }">
			<div class="absolute top-3 right-3 md:right-6">
				<button
					type="button"
					class="flex items-center justify-center w-8 h-8 text-black bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-300"
					@click="handleHideButtonClick"
				>
					<span class="sr-only">
						Close panel
					</span>
					<CloseIcon />
				</button>
			</div>
		</template>
		<template #header>
			<div class="absolute w-4/5 text-2xl font-bold truncate transition-all ease-in-out transform left-5 md:left-8 top-3">
				Filters
			</div>
		</template>
		<template #body>
			<BusinessMenuFilters
				ref="filters"
				class="px-12 py-6"
				:menu-filters="menuFilters"
				:total-items-count="totalItemsCount"
				:sub-category-name="subCategoryName"
			/>
		</template>
		<template #footer="{ handleHideButtonClick }">
			<div class="grid justify-start grid-cols-1 gap-2 pt-4 mx-12">
				<button
					class="text-sm font-semibold p-2.5 border bg-green-500 text-white hover:bg-green-300 border-green-500 rounded shadow-sm uppercase w-full"
					@click="handleHideButtonClick"
				>
					Apply Filters
				</button>
				<button
					class="text-sm font-semibold p-2.5 border border-gray-300 rounded shadow-sm uppercase w-full"
					@click="resetFilters"
				>
					Clear All
				</button>
			</div>
		</template>
	</WwModal>
</template>

<script async>
import { mapGetters } from 'vuex'

import BusinessMenuFilters from '@/components/business/BusinessMenuFilters.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import WwModal from '@/components/UI/WwModal.vue'
import { MENU_FILTER_MODAL } from '@/constants/modal/names.js'

export default {
	components: {
		BusinessMenuFilters,
		WwModal,
		CloseIcon
	},
	props: {
		menuFilters: {
			type: Object,
			required: true
		},
		subCategoryName: {
			type: String,
			required: true
		},
		totalItemsCount: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			MENU_FILTER_MODAL
		}
	},
	computed: {
		...mapGetters('modal', [ 'activeModalId' ])
	},
	methods: {
		resetFilters() {
			this.$router.push({ query: { category: this.$route.query.category } })
		}
	}
}
</script>
